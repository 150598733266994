//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useRef, useEffect, useMemo, createContext, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Switch from '@mui/material/Switch';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getElapsedTimeInDays, compareObjects } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { getPriorityScore, PaqEntry } from '../../models/Paq';
import { AccordionDetails, Autocomplete, Checkbox, CircularProgress, FilledInput, FormHelperText, Input, InputBase, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import { fr } from "date-fns/locale";
import { format, parseISO, formatISO, min } from "date-fns";
import { getServerURL } from "../../config";



const PaqEntryDialog = ({ isOpen, onClose, title, paqEntry, setPaqEntries }) => {
    const theme = useTheme();
    const { lists, selectedEstablishment } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();
    const { customer_hid } = useParams();
    const { control, handleSubmit, watch, reset, trigger, getValues } = useForm({
        mode: "all"
    });
    const [assignees, setAssignees] = useState([]);
    const [isAssigneesLoading, setIsAssigneesLoading] = useState(false);

    useEffect(() => {
        reset();
        setAssignees([]);

        //only fetch assignees if  isOpen is true
        if (!isOpen) return;

        const fetchAssignees = async () => {
            setIsAssigneesLoading(true);
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/paq/fetch_assignees`,
                method: 'get',
                showLoading: false,
                withCredentials: true
            });
            if (response.status === 200) {
                let assigneesTmp = response.data?.assignees || [];
                assigneesTmp.sort((a, b) => {
                    // Compare job labels
                    const jobA = lists?.JOB_LIST?.find(e => e.value === a.job)?.label?.toLowerCase();
                    const jobB = lists?.JOB_LIST?.find(e => e.value === b.job)?.label?.toLowerCase();
                    if (jobA < jobB) return -1;
                    if (jobA > jobB) return 1;

                    // If job labels are the same, compare firstnames
                    if (a.firstname?.toLowerCase() < b.firstname?.toLowerCase()) return -1;
                    if (a.firstname?.toLowerCase() > b.firstname?.toLowerCase()) return 1;

                    // If firstnames are the same, compare lastnames
                    if (a.lastname?.toLowerCase() < b.lastname?.toLowerCase()) return -1;
                    if (a.lastname?.toLowerCase() > b.lastname?.toLowerCase()) return 1;

                    return 0;
                })
                setAssignees(assigneesTmp);
                setIsAssigneesLoading(false);
            }
        }

        fetchAssignees();

    }, [paqEntry])

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    let priorityValues = {
        environmental_demand: watch("environmental_demand"),
        information_system_handling: watch("information_system_handling"),
        activity_handling: watch("activity_handling"),
        people_risk: watch("people_risk"),
        customer_care: watch("customer_care"),
        frequency: watch("frequency"),
        occurence: watch("occurence"),
        detectability: watch("detectability")
    }

    let dateValues = {
        start_date: watch("start_date"),
        due_date: watch("due_date"),
        completion_date: watch("completion_date")
    }

    const getPriorityMessage = () => {
        let score = getPriorityScore(priorityValues, lists);
        if (score) return `(score = ${score})`;
        return '';
    }

    const getDelayMessage = () => {
        if (!dateValues.due_date) return "";
        let delay = getElapsedTimeInDays(new Date(dateValues.due_date), dateValues.completion_date ? new Date(dateValues.completion_date) : undefined);
        if (delay && delay > 0) return `(retard = ${delay} jours)`;
        return "";
    }

    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit(async (data) => {
        //sanitize before send to server
        for (let key in data) {
            if (!data[key]) data[key] = undefined;
        }
        if (data.start_date) data.start_date = formatISO(data.start_date);
        if (data.due_date) data.due_date = formatISO(data.due_date);
        if (data.completion_date) data.completion_date = formatISO(data.completion_date);
        //copy paqEntry then override all the key values of paqEntry with the ones from data
        let paqEntryTmp = { ...paqEntry, ...data };
        let response = undefined;
        if (paqEntry?.clientAction === "new") {
            response = await httpRequest({
                method: 'post',
                url: `${getServerURL()}/customer/${customer_hid}/${paqEntryTmp.establishment_id}/paq/new_entry`,
                data: { paqEntry: paqEntryTmp },
                headers: { "Content-type": "Application/json" },
                withCredentials: true
            });
        } else {
            //check if paqEntry has been modified before call service
            let hasChanged = !compareObjects({ obj1: paqEntryTmp, obj2: paqEntry, falsyEquals: true });
            if (hasChanged) {
                response = await httpRequest({
                    method: 'post',
                    url: `${getServerURL()}/customer/${customer_hid}/${paqEntryTmp.establishment_id}/paq/update_entry`,
                    data: { paqEntry: paqEntryTmp },
                    headers: { "Content-type": "Application/json" },
                    withCredentials: true
                });
            } else {
                presentToast({
                    severity: "warning",
                    message: "L'action n'a pas été modifiée"
                })
            }
        }
        if (response?.status === 200) {
            presentToast({
                severity: "success",
                message: `${title}: succès`
            })
            let tmp = response.data.paqEntries.map(paqEntry => new PaqEntry(paqEntry, lists));
            setPaqEntries(tmp);
            onClose();
        }
    })

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            // onClose={ }
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Informations principales</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="imperative_criterion"
                                        rules={{}}
                                        defaultValue={paqEntry?.imperative_criterion || false}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ maxWidth: "fit-content" }} variant="standard" error={error?.message ? true : false}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={value}
                                                                onChange={onChange}
                                                            />
                                                        }
                                                        label="Critère impératif"
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="objective"
                                        rules={{ required: "Objectif requis" }}
                                        defaultValue={paqEntry?.objective || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={!!error?.message}>
                                                    <Autocomplete
                                                        value={lists?.OBJECTIVE_PAQ_LIST?.find(e => e.value === value) || null}
                                                        onChange={(event, newValue) => {
                                                            onChange(newValue?.value || null)
                                                        }}
                                                        options={lists?.OBJECTIVE_PAQ_LIST || []}
                                                        getOptionLabel={(option) => option.label || ''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Objectif*"
                                                                variant="standard"
                                                                error={!!error?.message}
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="action"
                                        rules={{ required: "Action requise", minLength: { value: 5, message: '5 caracteres minimum' } }}
                                        defaultValue={paqEntry?.action || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Action*"
                                                        variant="standard"
                                                        value={value}
                                                        multiline
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="source"
                                        rules={{ validate: (value) => value.length > 0 || "Au moins une source requise" }}
                                        defaultValue={paqEntry?.source || []}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={!!error?.message}>
                                                    <Autocomplete
                                                        multiple
                                                        disableCloseOnSelect
                                                        value={value.map(elValue => lists?.SOURCE_PAQ_LIST?.find(e => e.value === elValue)) || []}
                                                        onChange={(event, newValue) => {
                                                            onChange(newValue.map(e => e.value) || [])
                                                        }}
                                                        options={lists?.SOURCE_PAQ_LIST || []}
                                                        getOptionLabel={(option) => option?.label || ''}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option?.label}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Source*"
                                                                variant="standard"
                                                                error={!!error?.message}
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"assignee"}
                                        rules={{ required: "Pilote requis" }}
                                        defaultValue={paqEntry?.assignee?._id || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={assignees}
                                                        value={assignees?.find(e => e?._id === value) || null}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option?._id === value._id
                                                        }}
                                                        getOptionLabel={option => {
                                                            return `${lists?.JOB_LIST?.find(e => e.value === option?.job)?.label} - ${option?.firstname} ${option?.lastname}`;
                                                        }}
                                                        onChange={(event, selectedOption) => {
                                                            return onChange(selectedOption?._id || null)
                                                        }}
                                                        renderInput={(params) => {
                                                            return <TextField
                                                                {...params}
                                                                label="Pilote*"
                                                                variant="standard"
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                                error={!!error?.message}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: isAssigneesLoading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null,
                                                                }}
                                                            />
                                                        }}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary>
                                <Typography>Priorité {getPriorityMessage()}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="environmental_demand"
                                        rules={{}}
                                        defaultValue={paqEntry?.environmental_demand || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={!!error?.message}>
                                                    <Autocomplete
                                                        value={lists?.ENVIRONMENTAL_DEMAND_PAQ_LIST?.find(e => e.value === value) || null}
                                                        onChange={(event, newValue) => {
                                                            onChange(newValue?.value || null)
                                                        }}
                                                        options={lists?.ENVIRONMENTAL_DEMAND_PAQ_LIST || []}
                                                        getOptionLabel={(option) => option?.label || ''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Exigence environnementale"
                                                                variant="standard"
                                                                error={!!error?.message}
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                            />
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                                {option?.label}
                                                            </li>
                                                        )}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Box />{/*added here only to add extra margin*/}
                                    <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', position: 'relative' }}>
                                        <Typography variant="caption" sx={{ position: 'absolute', top: '-10px', left: '10px', backgroundColor: 'white', padding: '0 4px' }}>
                                            Sélectionner une valeur au choix
                                        </Typography>
                                        <Controller
                                            name="information_system_handling"
                                            rules={{}}
                                            defaultValue={paqEntry?.information_system_handling || null}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={!!error?.message}>
                                                        <Autocomplete
                                                            value={lists?.INFORMATION_SYS_PAQ_LIST?.find(e => e.value === value) || null}
                                                            onChange={(event, newValue) => onChange(newValue?.value || null)}
                                                            options={lists?.INFORMATION_SYS_PAQ_LIST || []}
                                                            getOptionLabel={(option) => option?.label || ''}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Gestion système d'informations"
                                                                    variant="standard"
                                                                    error={!!error?.message}
                                                                    inputRef={ref}
                                                                    helperText={error?.message}
                                                                />
                                                            )}
                                                            renderOption={(props, option) => (
                                                                <li {...props}>
                                                                    {option?.label}
                                                                </li>
                                                            )}
                                                            disabled={
                                                                (priorityValues.activity_handling ||
                                                                    priorityValues.people_risk ||
                                                                    priorityValues.customer_care) !== null
                                                            }
                                                        />
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                        <Controller
                                            name="activity_handling"
                                            rules={{}}
                                            defaultValue={paqEntry?.activity_handling || null}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={!!error?.message}>
                                                        <Autocomplete
                                                            value={lists?.ACTIVITY_HANDLING_PAQ_LIST?.find(e => e.value === value) || null}
                                                            onChange={(event, newValue) => onChange(newValue?.value || null)}
                                                            options={lists?.ACTIVITY_HANDLING_PAQ_LIST || []}
                                                            getOptionLabel={(option) => option?.label || ''}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Gestion organisation"
                                                                    variant="standard"
                                                                    error={!!error?.message}
                                                                    inputRef={ref}
                                                                    helperText={error?.message}
                                                                />
                                                            )}
                                                            renderOption={(props, option) => (
                                                                <li {...props}>
                                                                    {option?.label}
                                                                </li>
                                                            )}
                                                            disabled={
                                                                (priorityValues.information_system_handling ||
                                                                    priorityValues.people_risk ||
                                                                    priorityValues.customer_care) !== null
                                                            }
                                                        />
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                        <Controller
                                            name="people_risk"
                                            rules={{}}
                                            defaultValue={paqEntry?.people_risk || null}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                        <Autocomplete
                                                            value={lists?.PEOPLE_RISK_PAQ_LIST?.find(e => e.value === value) || null}
                                                            onChange={(event, newValue) => onChange(newValue?.value || null)}
                                                            options={lists?.PEOPLE_RISK_PAQ_LIST || []}
                                                            getOptionLabel={(option) => option?.label || ''}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Risque pour la personne"
                                                                    variant="standard"
                                                                    error={!!error?.message}
                                                                    inputRef={ref}
                                                                    helperText={error?.message}
                                                                />
                                                            )}
                                                            renderOption={(props, option) => (
                                                                <li {...props}>
                                                                    {option?.label}
                                                                </li>
                                                            )}
                                                            disabled={
                                                                (priorityValues.information_system_handling ||
                                                                    priorityValues.activity_handling ||
                                                                    priorityValues.customer_care) !== null
                                                            }
                                                        />
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                        <Controller
                                            name="customer_care"
                                            rules={{}}
                                            defaultValue={paqEntry?.customer_care || null}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                        <Autocomplete
                                                            value={lists?.CUSTOMER_CARE_PAQ_LIST?.find(e => e.value === value) || null}
                                                            onChange={(event, newValue) => onChange(newValue?.value || null)}
                                                            options={lists?.CUSTOMER_CARE_PAQ_LIST || []}
                                                            getOptionLabel={(option) => option?.label || ''}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Écoute usagers"
                                                                    variant="standard"
                                                                    error={!!error?.message}
                                                                    inputRef={ref}
                                                                    helperText={error?.message}
                                                                />
                                                            )}
                                                            renderOption={(props, option) => (
                                                                <li {...props}>
                                                                    {option?.label}
                                                                </li>
                                                            )}
                                                            disabled={
                                                                (priorityValues.information_system_handling ||
                                                                    priorityValues.activity_handling ||
                                                                    priorityValues.people_risk) !== null
                                                            }
                                                        />
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Controller
                                        name="frequency"
                                        rules={{}}
                                        defaultValue={paqEntry?.frequency || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        value={lists?.FREQUENCY_PAQ_LIST?.find(e => e.value === value) || null}
                                                        onChange={(event, newValue) => onChange(newValue?.value || null)}
                                                        options={lists?.FREQUENCY_PAQ_LIST || []}
                                                        getOptionLabel={(option) => option?.label || ''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Fréquence de la pratique en lien avec l'action"
                                                                variant="standard"
                                                                error={!!error?.message}
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                            />
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                                {option?.label}
                                                            </li>
                                                        )}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="occurence"
                                        rules={{}}
                                        defaultValue={paqEntry?.occurence || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        value={lists?.OCCURENCE_PAQ_LIST?.find(e => e.value === value) || null}
                                                        onChange={(event, newValue) => onChange(newValue?.value || null)}
                                                        options={lists?.OCCURENCE_PAQ_LIST || []}
                                                        getOptionLabel={(option) => option?.label || ''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Occurence"
                                                                variant="standard"
                                                                error={!!error?.message}
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                            />
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                                {option?.label}
                                                            </li>
                                                        )}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="detectability"
                                        rules={{}}
                                        defaultValue={paqEntry?.detectability || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        value={lists?.DETECTABILITY_PAQ_LIST?.find(e => e.value === value) || null}
                                                        onChange={(event, newValue) => onChange(newValue?.value || null)}
                                                        options={lists?.DETECTABILITY_PAQ_LIST || []}
                                                        getOptionLabel={(option) => option?.label || ''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Détectabilité: s'il y a un EI, une plainte ou une réclamation, peut-on le détecter ?"
                                                                variant="standard"
                                                                error={!!error?.message}
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                            />
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                                {option?.label}
                                                            </li>
                                                        )}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary>
                                <Typography>Dates {getDelayMessage()}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="start_date"
                                        rules={{
                                            validate: value => {
                                                let td = getValues("due_date");
                                                let ed = getValues("completion_date");
                                                const validateTargetDate = !value || !td || value <= td || false;
                                                const validateEndDate = !value || !ed || value <= ed || false;
                                                if (!validateTargetDate) {
                                                    return "Ne peut être après la date d'échéance";
                                                } else if (!validateEndDate) {
                                                    return "Ne peut être après la date de clôture";
                                                } else return true;
                                            }
                                        }}
                                        defaultValue={paqEntry?.start_date ? parseISO(paqEntry?.start_date) : null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth error={error?.message ? true : false}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                                        <DatePicker
                                                            value={value}
                                                            onChange={async (newValue) => {
                                                                onChange(newValue);
                                                                await trigger(["due_date", "completion_date"]);
                                                            }}
                                                            label="Date de début de l'action"
                                                            ref={ref}
                                                            maxDate={dateValues.due_date ? (dateValues.completion_date ? min([dateValues.due_date, dateValues.completion_date]) : dateValues.due_date) : dateValues.completion_date}
                                                            slotProps={{
                                                                textField: {
                                                                    error: !!error?.message,
                                                                    helperText: error?.message,
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="due_date"
                                        rules={{
                                            validate: value => (!value || !getValues("start_date") || (value >= getValues("start_date")) || "Ne peut être avant la date de début")
                                        }}
                                        defaultValue={paqEntry?.due_date ? parseISO(paqEntry?.due_date) : null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth error={error?.message ? true : false}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                                        <DatePicker
                                                            value={value}
                                                            onChange={async (newValue) => {
                                                                onChange(newValue);
                                                                await trigger(["start_date"]);
                                                            }}
                                                            label="Date d'échéance prévisionnelle de l'action"
                                                            ref={ref}
                                                            minDate={dateValues.start_date}
                                                            slotProps={{
                                                                textField: {
                                                                    error: !!error?.message,
                                                                    helperText: error?.message,
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="completion_date"
                                        rules={{
                                            validate: value => (!value || !getValues("start_date") || (value >= getValues("start_date")) || "Ne peut être avant la date de début")
                                        }}
                                        defaultValue={paqEntry?.completion_date ? parseISO(paqEntry?.completion_date) : null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth error={error?.message ? true : false}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                                        <DatePicker
                                                            value={value}
                                                            onChange={async (newValue) => {
                                                                onChange(newValue);
                                                                await trigger(["start_date"]);
                                                            }}
                                                            label="Date de clôture de l'action"
                                                            ref={ref}
                                                            minDate={dateValues.start_date}
                                                            slotProps={{
                                                                textField: {
                                                                    error: !!error?.message,
                                                                    helperText: error?.message,
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary>
                                <Typography>Statut et commentaires</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="comment"
                                        rules={{}}
                                        defaultValue={paqEntry?.comment || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Commentaire"
                                                        variant="standard"
                                                        value={value}
                                                        multiline
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="status"
                                        rules={{}}
                                        defaultValue={paqEntry?.status || "1"} //non débuté par défaut 
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <InputLabel>Statut</InputLabel>
                                                    <Select
                                                        value={value}
                                                        onChange={event => {
                                                            onChange(event?.target?.value || "")
                                                        }}
                                                        error={error?.message ? true : false}
                                                        inputRef={ref}
                                                    >
                                                        {lists?.STATUS_PAQ_LIST?.map(status => (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status?.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="reason"
                                        rules={{
                                            required: {
                                                value: watch('status') === '4',
                                                message: "Raison requise si statut abandonné"
                                            }
                                        }}
                                        defaultValue={paqEntry?.reason || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label={`Raison si abandonné ou en retard${watch('status') === '4' ? "*" : ""}`}
                                                        variant="standard"
                                                        value={value}
                                                        multiline
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            Annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                            variant="contained"
                        >
                            Valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

export { PaqEntryDialog }