//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import WarningIcon from '@mui/icons-material/Warning';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ClearIcon from '@mui/icons-material/Clear';

import RadioIcon from "../../icons/RadioIcon";
import CheckedRadioIcon from "../../icons/CheckedRadioIcon";

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getElapsedTimeInDays, compareObjects, fromArrayToObject } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { PaqEntry } from '../../models/Paq';
import { AccordionDetails, Autocomplete, Checkbox, CircularProgress, Stack, Tooltip } from "@mui/material";
import { fr } from "date-fns/locale";
import { parseISO, formatISO, min } from "date-fns";
import { getServerURL } from "../../config";
import { colors, getColor } from "../../theme/colors";


const FieldIconButton = ({ resetField }) => {
    const modifiedColor = getColor(colors.pink.tint1);

    return (
        <Tooltip title={<>Champ modifié. Annuler la modification.</>}>
            <IconButton
                sx={{
                    color: modifiedColor,
                    padding: '4px', // Adjust padding to match the default icon size
                    '& .MuiSvgIcon-root': {
                        fontSize: '20px', // Adjust icon size to match the default icon size
                    },
                }}
                onClick={(event) => {
                    event.preventDefault();
                    resetField();
                }}
            >
                <WarningIcon />
            </IconButton>
        </Tooltip>
    );
}

const PaqEntriesDialog = ({ isOpen, onClose, title, editedPaqEntries, listPaq, setPaqEntries }) => {
    const theme = useTheme();
    const { lists, selectedEstablishment } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();
    const { customer_hid } = useParams();
    const { control, handleSubmit, watch, reset, resetField, getValues, trigger, formState: { isDirty, dirtyFields } } = useForm({
        mode: "all"
    });
    const [assignees, setAssignees] = useState([]);
    const [isAssigneesLoading, setIsAssigneesLoading] = useState(false);
    const [hasMultipleValues, setHasMultipleValues] = useState({});


    useEffect(() => {
        reset();
        setAssignees([]);

        //only fetch assignees if isOpen is true
        if (!isOpen || editedPaqEntries?.length < 2) return;

        const fetchAssignees = async () => {
            setIsAssigneesLoading(true);
            let response = await httpRequest({
                url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/paq/fetch_assignees`,
                method: 'get',
                showLoading: false,
                withCredentials: true
            });
            if (response.status === 200) {
                let assigneesTmp = response.data?.assignees || [];
                assigneesTmp.sort((a, b) => {
                    // Compare job labels
                    const jobA = lists?.JOB_LIST?.find(e => e.value === a.job)?.label?.toLowerCase();
                    const jobB = lists?.JOB_LIST?.find(e => e.value === b.job)?.label?.toLowerCase();
                    if (jobA < jobB) return -1;
                    if (jobA > jobB) return 1;

                    // If job labels are the same, compare firstnames
                    if (a.firstname?.toLowerCase() < b.firstname?.toLowerCase()) return -1;
                    if (a.firstname?.toLowerCase() > b.firstname?.toLowerCase()) return 1;

                    // If firstnames are the same, compare lastnames
                    if (a.lastname?.toLowerCase() < b.lastname?.toLowerCase()) return -1;
                    if (a.lastname?.toLowerCase() > b.lastname?.toLowerCase()) return 1;

                    return 0;
                });
                setAssignees(assigneesTmp);
                setIsAssigneesLoading(false);
            }
        }

        fetchAssignees();

        let defaultValues = {};
        let hasMultipleValuesTmp = {};
        const keysData = [
            { key: "imperative_criterion", fallbackValue: null, transformFn: (val) => (!val ? "non" : "oui") },
            { key: "objective", fallbackValue: null },
            { key: "source", fallbackValue: [] },
            { key: "assignee", fallbackValue: null },
            { key: "start_date", fallbackValue: null, transformFn: parseISO },
            { key: "due_date", fallbackValue: null, transformFn: parseISO },
            { key: "completion_date", fallbackValue: null, transformFn: parseISO },
            { key: "comment", fallbackValue: "" },
            { key: "status", fallbackValue: "" },
            { key: "reason", fallbackValue: "" },
        ];

        for (let data of keysData) {
            let { defaultValue, hasMultipleValues } = getDefaultValue({ ...data });
            defaultValues[data.key] = defaultValue;
            hasMultipleValuesTmp[data.key] = hasMultipleValues;
        }

        reset(defaultValues);
        setHasMultipleValues(hasMultipleValuesTmp);

    }, [editedPaqEntries]);

    const compareKeyArrayElements = (array, key) => {
        if (!array?.length || array.length <= 1) return true;
        for (let i = 1; i < array?.length; i++) {
            let isSameValue = compareObjects({ obj1: array[i - 1][key], obj2: array[i][key], falsyEquals: true, checkOrderInArrays: false, includeObj2Keys: true });
            if (!isSameValue) {
                return false;
            }
        }
        return true;
    }

    /**
     * 
     * @param {Object} params - The param object.
     * @param {String} params.key - mandatory: one of editedPaqEntries key that matches the defaultValue you want to get
     * @param {any} params.fallbackValue - mandatory: provide a fallbackValue in case none of the editedPaqEntries elements have a defined value
     * @param {Function} params.transformFn - optional: transform value with provided transformFn before sending defaultValues, useful for dates for example
     * @returns {any} related defaultValue
     */
    const getDefaultValue = ({ key, fallbackValue, transformFn }) => {
        let areSameArrayElements = compareKeyArrayElements(editedPaqEntries, key);
        let defaultValue = undefined;
        if (areSameArrayElements) {
            if (transformFn) {
                defaultValue = (typeof editedPaqEntries?.[0]?.[key] === "boolean" || editedPaqEntries?.[0]?.[key]) ? transformFn(editedPaqEntries[0][key]) : fallbackValue;
            } else {
                defaultValue = editedPaqEntries?.[0]?.[key] ? editedPaqEntries[0][key] : fallbackValue;
            }
        } else {
            defaultValue = fallbackValue;
        }
        return { defaultValue: defaultValue, hasMultipleValues: !areSameArrayElements };
    }

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    let dateValues = {
        start_date: watch("start_date"),
        due_date: watch("due_date"),
        completion_date: watch("completion_date")
    }

    const getDelayMessage = () => {
        if (!dateValues.due_date) return "";
        let delay = getElapsedTimeInDays(new Date(dateValues.due_date), dateValues.completion_date ? new Date(dateValues.completion_date) : undefined);
        if (delay && delay > 0) return `(retard = ${delay} jours)`;
        return "";
    }

    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit(async (data) => {

        //if form is not dirty presentToast
        if (!isDirty) {
            presentToast({
                severity: "warning",
                message: "Les actions n'ont pas été modifiées"
            });
            return;
        }

        //prepare data to be sent
        //keep only dirtyFields
        for (let key in data) {
            if (!dirtyFields?.[key]) delete data[key];
        }
        //transform remaining data: dates to ISO strings, imperative criterion to Boolean set to false by default
        if (data.start_date) data.start_date = formatISO(data.start_date);
        if (data.due_date) data.due_date = formatISO(data.due_date);
        if (data.completion_date) data.completion_date = formatISO(data.completion_date);

        //if imperative_criterion is not dirty, set imperative_criterion to false if all editedPaqEntries are undefined or false
        if (!dirtyFields["imperative_criterion"]) {
            let shouldSetToFalse = true;
            for (let pe of editedPaqEntries) {
                if (pe?.imperative_criterion) {
                    shouldSetToFalse = false;
                }
            }
            if (shouldSetToFalse) {
                data.imperative_criterion = false;
            }
        } else {
            data.imperative_criterion = data.imperative_criterion === "oui" ? true : false;
        }

        //copy paqEntry then override all the key values of paqEntry with the ones from data
        let editedPaqEntriesTmp = editedPaqEntries.map(paqEntry => ({ ...paqEntry, ...data }));

        let response = await httpRequest({
            method: 'post',
            url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/paq/update_entries`,
            data: { paqEntries: editedPaqEntriesTmp },
            headers: { "Content-type": "Application/json" },
            withCredentials: true
        });
        if (response?.status === 200) {
            presentToast({
                severity: "success",
                message: `${title}: succès`
            })
            let tmp = response.data.paqEntries.map(paqEntry => new PaqEntry(paqEntry, lists));
            setPaqEntries(tmp);
            onClose();
        }
    });

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            // onClose={ }
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Informations principales</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="imperative_criterion"
                                        rules={{}}
                                        control={control}
                                        render={({ field, fieldState }) => {
                                            const radioValues = [{ value: "oui", label: "oui" }, { value: "non", label: "non" }];
                                            return (
                                                <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', position: 'relative', marginTop: "12px !important" }}>
                                                    <Box sx={{ display: "flex", alignItems: "center", position: 'absolute', top: '-10px', left: '10px', backgroundColor: 'white', padding: '0 4px' }}>
                                                        <Typography variant="caption">
                                                            Critère impératif
                                                        </Typography>
                                                    </Box>
                                                    <RadioGroup {...field}>
                                                        {radioValues.map((radioValue) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={radioValue.value}
                                                                    value={radioValue.value}
                                                                    control={
                                                                        <Radio
                                                                            icon={<RadioIcon />}
                                                                            checkedIcon={<CheckedRadioIcon />}
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                            <Typography sx={{ width: "35px" }}>
                                                                                {radioValue.label}
                                                                            </Typography>
                                                                            {(fieldState?.isDirty && radioValue.value === field.value) &&
                                                                                <FieldIconButton
                                                                                    resetField={() => resetField(field.name)}
                                                                                />
                                                                            }
                                                                        </Box>
                                                                    }
                                                                />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </Box>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="objective"
                                        rules={{
                                            validate: value => {
                                                if (dirtyFields["objective"] && !value) {
                                                    return "Objectif requis"
                                                }
                                                return true;
                                            }
                                        }}
                                        // defaultValue={getDefaultValue({ key: "objective", fallbackValue: null })}
                                        control={control}
                                        render={({ field, fieldState: { error, isDirty } }) => {
                                            const { onChange, value, ref } = field;
                                            let objectiveList = fromArrayToObject(lists?.OBJECTIVE_PAQ_LIST || [], "value");
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={!!error?.message}>
                                                    <Autocomplete
                                                        value={value}
                                                        onChange={(event, newValue) => onChange(newValue)}
                                                        options={Object.keys(objectiveList)}
                                                        getOptionLabel={(option) => objectiveList[option]?.label || ''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Objectif"
                                                                variant="standard"
                                                                error={!!error?.message}
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {isDirty &&
                                                                                <FieldIconButton
                                                                                    resetField={() => resetField(field.name)}
                                                                                />
                                                                            }
                                                                            {params.InputProps.endAdornment}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="source"
                                        rules={{
                                            validate: value => {
                                                if (dirtyFields["source"] && value?.length < 1) {
                                                    return "Source requise"
                                                }
                                                return true;
                                            }
                                        }}
                                        control={control}
                                        render={({ field, fieldState: { error, isDirty } }) => {
                                            const { onChange, value, ref } = field;
                                            let sourceList = fromArrayToObject(lists?.SOURCE_PAQ_LIST || [], "value");
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={!!error?.message}>
                                                    <Autocomplete
                                                        multiple
                                                        disableCloseOnSelect
                                                        value={value}
                                                        onChange={(event, newValue) => onChange(newValue)}
                                                        options={Object.keys(sourceList)}
                                                        getOptionLabel={(option) => sourceList[option]?.label || ''}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {sourceList[option]?.label}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Source"
                                                                variant="standard"
                                                                error={!!error?.message}
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {isDirty &&
                                                                                <FieldIconButton
                                                                                    resetField={() => resetField(field.name)}
                                                                                />
                                                                            }
                                                                            {params.InputProps.endAdornment}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"assignee"}
                                        rules={{
                                            validate: value => {
                                                if (dirtyFields["assignee"] && !value) {
                                                    return "Pilote requis"
                                                }
                                                return true;
                                            }
                                        }}
                                        control={control}
                                        render={({ field, fieldState: { error, isDirty } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={assignees}
                                                        value={assignees?.find(e => e?._id === value) || null}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option?._id === value?._id
                                                        }}
                                                        getOptionLabel={option => {
                                                            return `${lists?.JOB_LIST?.find(e => e.value === option?.job)?.label || ""} - ${option?.firstname} ${option?.lastname}`;
                                                        }}
                                                        onChange={(event, selectedOption) => {
                                                            return onChange(selectedOption?._id || null);
                                                        }}
                                                        renderInput={(params) => {
                                                            return <TextField
                                                                {...params}
                                                                label="Pilote"
                                                                variant="standard"
                                                                inputRef={ref}
                                                                error={!!error?.message}
                                                                helperText={error?.message}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: isAssigneesLoading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {isDirty &&
                                                                                <FieldIconButton
                                                                                    resetField={() => resetField(field.name)}
                                                                                />
                                                                            }
                                                                            {params.InputProps.endAdornment}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        }}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary>
                                <Typography>Dates {getDelayMessage()}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="start_date"
                                        rules={{
                                            validate: value => {
                                                let td = getValues("due_date");
                                                let ed = getValues("completion_date");
                                                const validateTargetDate = !value || !td || value <= td || false;
                                                const validateEndDate = !value || !ed || value <= ed || false;
                                                if (!validateTargetDate) {
                                                    return "Ne peut être après la date d'échéance";
                                                } else if (!validateEndDate) {
                                                    return "Ne peut être après la date de clôture";
                                                } else return true;
                                            }
                                        }}
                                        control={control}
                                        render={({ field, fieldState: { isDirty, error } }) => {
                                            const { onChange, value } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                                        <DatePicker
                                                            value={value}
                                                            onChange={async (newValue) => {
                                                                onChange(newValue);
                                                                await trigger(["due_date", "completion_date"]);
                                                            }}
                                                            label="Date de début de l'action"
                                                            maxDate={dateValues.due_date ? (dateValues.completion_date ? min([dateValues.due_date, dateValues.completion_date]) : dateValues.due_date) : dateValues.completion_date}
                                                            slots={{
                                                                textField: props => (
                                                                    < TextField
                                                                        {...props}
                                                                        error={!!error?.message}
                                                                        helperText={error?.message}
                                                                        InputProps={{
                                                                            ...props.InputProps,
                                                                            endAdornment: (
                                                                                <Stack direction="row" alignItems="center">
                                                                                    {isDirty &&
                                                                                        <InputAdornment position="end">
                                                                                            <FieldIconButton
                                                                                                resetField={() => resetField(field.name)}
                                                                                            />
                                                                                        </InputAdornment>
                                                                                    }
                                                                                    {props.InputProps.endAdornment}
                                                                                </Stack>
                                                                            )
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="due_date"
                                        rules={{
                                            validate: value => (!value || !getValues("start_date") || (value >= getValues("start_date")) || "Ne peut être avant la date de début")
                                        }}
                                        control={control}
                                        render={({ field, fieldState: { isDirty, error } }) => {
                                            const { onChange, value } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                                        <DatePicker
                                                            value={value}
                                                            onChange={async (newValue) => {
                                                                onChange(newValue);
                                                                await trigger(["start_date"]);
                                                            }}
                                                            label="Date d'échéance prévisionnelle de l'action"
                                                            minDate={dateValues.start_date}
                                                            slots={{
                                                                textField: props => (
                                                                    <TextField
                                                                        {...props}
                                                                        error={!!error?.message}
                                                                        helperText={error?.message}
                                                                        InputProps={{
                                                                            ...props.InputProps,
                                                                            endAdornment: (
                                                                                <Stack direction="row" alignItems="center">
                                                                                    {isDirty &&
                                                                                        <InputAdornment position="end">
                                                                                            <FieldIconButton
                                                                                                resetField={() => resetField(field.name)}
                                                                                            />
                                                                                        </InputAdornment>
                                                                                    }
                                                                                    {props.InputProps.endAdornment}
                                                                                </Stack>
                                                                            )
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="completion_date"
                                        rules={{
                                            validate: value => (!value || !getValues("start_date") || (value >= getValues("start_date")) || "Ne peut être avant la date de début")
                                        }}
                                        control={control}
                                        render={({ field, fieldState: { isDirty, error } }) => {
                                            const { onChange, value } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                                        <DatePicker
                                                            value={value}
                                                            onChange={async (newValue) => {
                                                                onChange(newValue);
                                                                await trigger(["start_date"]);
                                                            }}
                                                            label="Date de clôture de l'action"
                                                            minDate={dateValues.start_date}
                                                            slots={{
                                                                textField: props => (
                                                                    <TextField
                                                                        {...props}
                                                                        error={!!error?.message}
                                                                        helperText={error?.message}
                                                                        InputProps={{
                                                                            ...props.InputProps,
                                                                            endAdornment: (
                                                                                <Stack direction="row" alignItems="center">
                                                                                    {isDirty &&
                                                                                        <InputAdornment position="end">
                                                                                            <FieldIconButton
                                                                                                resetField={() => resetField(field.name)}
                                                                                            />
                                                                                        </InputAdornment>
                                                                                    }
                                                                                    {props.InputProps.endAdornment}
                                                                                </Stack>
                                                                            )
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary>
                                <Typography>Statut et commentaires</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="comment"
                                        rules={{}}
                                        control={control}
                                        render={({ field, fieldState: { error, isDirty } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Commentaire"
                                                        variant="standard"
                                                        value={value}
                                                        multiline
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                        helperText={error?.message}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Stack direction="row" alignItems="center" sx={{ gap: "8px" }}>
                                                                        {isDirty && (
                                                                            <FieldIconButton resetField={() => resetField(field.name)} />
                                                                        )}
                                                                        {field.value &&
                                                                            <IconButton
                                                                                onClick={() => onChange('')}
                                                                                sx={{ fontSize: "20px", padding: 0 }}
                                                                            >
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        }
                                                                    </Stack>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="status"
                                        rules={{}}
                                        control={control}
                                        render={({ field, fieldState: { error, isDirty } }) => {
                                            const { onChange, value, ref } = field;
                                            const statusOptions = lists?.STATUS_PAQ_LIST;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={!!error?.message}>
                                                    <Autocomplete
                                                        options={statusOptions}
                                                        getOptionLabel={(option) => option.label}
                                                        value={statusOptions.find(option => option.value === value) || null}
                                                        onChange={(event, newValue) => {
                                                            onChange(newValue ? newValue.value : '');
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Statut"
                                                                variant="standard"
                                                                error={!!error?.message}
                                                                inputRef={ref}
                                                                helperText={error?.message}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {isDirty &&
                                                                                <FieldIconButton
                                                                                    resetField={() => resetField(field.name)}
                                                                                />
                                                                            }
                                                                            {params.InputProps.endAdornment}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="reason"
                                        rules={{
                                            required: {
                                                value: watch('status') === '4',
                                                message: "Raison requise si statut abandonné"
                                            }
                                        }}
                                        control={control}
                                        render={({ field, fieldState: { error, isDirty } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label={`Raison si abandonné ou en retard${watch('status') === '4' ? "*" : ""}`}
                                                        variant="standard"
                                                        value={value}
                                                        multiline
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                        helperText={error?.message}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Stack direction="row" alignItems="center" sx={{ gap: "8px" }}>
                                                                        {isDirty && (
                                                                            <FieldIconButton resetField={() => resetField(field.name)} />
                                                                        )}
                                                                        {field.value &&
                                                                            <IconButton
                                                                                onClick={() => onChange('')}
                                                                                sx={{ fontSize: "20px", padding: 0 }}
                                                                            >
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        }
                                                                    </Stack>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </FormControl>
                                            )
                                        }}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            Annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                            variant="contained"
                        >
                            Valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

export { PaqEntriesDialog }