
import { colors, getColor } from "../../theme/colors";

const fillColor = getColor(colors.pink.tint1);

const ImperativeSign = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 23"
            fill="none"
            style={{ display: "block" }}
        >
            <circle cx="12" cy="12" r="11" fill={fillColor} />
            <text
                x="12px"
                y="18px"
                textAnchor="middle"
                fill="white"
                fontSize="16"
                fontWeight="bold"
            >
                !
            </text>
        </svg>
    );
}


export { ImperativeSign }