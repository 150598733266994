import { v1 as uuid } from "uuid";

const getPriorityScore = (data, lists) => {
    let environmental_demand = data?.environmental_demand ? lists?.ENVIRONMENTAL_DEMAND_PAQ_LIST?.find(e => e.value === data?.environmental_demand)?.grade : undefined;
    if (!environmental_demand) return "";
    let information_system_handling = data?.information_system_handling ? lists?.INFORMATION_SYS_PAQ_LIST?.find(e => e.value === data?.information_system_handling)?.grade : undefined;
    let activity_handling = data?.activity_handling ? lists?.ACTIVITY_HANDLING_PAQ_LIST?.find(e => e.value === data?.activity_handling)?.grade : undefined;
    let people_risk = data?.people_risk ? lists?.PEOPLE_RISK_PAQ_LIST?.find(e => e.value === data?.people_risk)?.grade : undefined;
    let customer_care = data?.customer_care ? lists?.CUSTOMER_CARE_PAQ_LIST?.find(e => e.value === data?.customer_care)?.grade : undefined;
    //only ONE of the four values should be defined -> we check and send error if necessary
    if (information_system_handling && (activity_handling || people_risk || customer_care)) return "erreur";
    else if (activity_handling && (information_system_handling || people_risk || customer_care)) return "erreur";
    else if (people_risk && (information_system_handling || activity_handling || customer_care)) return "erreur";
    else if (customer_care && (information_system_handling || activity_handling || people_risk)) return "erreur";
    else if (!information_system_handling && !activity_handling && !people_risk && !customer_care) return "";
    let frequency = data?.frequency ? lists?.FREQUENCY_PAQ_LIST?.find(e => e.value === data?.frequency)?.grade : undefined;
    if (!frequency) return "";
    let occurence = data?.occurence ? lists?.OCCURENCE_PAQ_LIST?.find(e => e.value === data?.occurence)?.grade : undefined;
    if (occurence === undefined) return "";
    let detectability = data?.detectability ? lists?.DETECTABILITY_PAQ_LIST?.find(e => e.value === data?.detectability)?.grade : undefined;
    if (!detectability) return "";
    let score = environmental_demand + (information_system_handling || activity_handling || people_risk || customer_care) * frequency * detectability + occurence;
    return score.toString();
}


class PaqEntry {
    constructor(p, lists) {
        this._id = p?._id;
        this.establishment_id = p?.establishment_id;
        this.action_number = p?.action_number || ""; //Material React Table global filter will only work if empty string instead of undefined
        this.source = p?.source;
        this.imperative_criterion = p?.imperative_criterion;
        this.objective = p?.objective;
        this.action = p?.action || "";
        this.status = p?.status;
        this.start_date = p?.start_date;
        this.assignee = p?.assignee;
        this.due_date = p?.due_date;
        this.completion_date = p?.completion_date;
        this.comment = p?.comment || "";
        this.reason = p?.reason || "";
        this.environmental_demand = p?.environmental_demand;
        this.information_system_handling = p?.information_system_handling;
        this.activity_handling = p?.activity_handling;
        this.people_risk = p?.people_risk;
        this.customer_care = p?.customer_care;
        this.frequency = p?.frequency;
        this.occurence = p?.occurence;
        this.detectability = p?.detectability;
        this.created = {
            by: p?.created?.by,
            on: p?.created?.on,
        }
        this.updated = {
            by: p?.updated?.by,
            on: p?.updated?.on,
        }
        //field added for client purpose
        this.priority = getPriorityScore({
            environmental_demand: this.environmental_demand,
            information_system_handling: this.information_system_handling,
            activity_handling: this.activity_handling,
            people_risk: this.people_risk,
            customer_care: this.customer_care,
            frequency: this.frequency,
            occurence: this.occurence,
            detectability: this.detectability
        }, lists)
        this.clientAction = p?.clientAction;
        this.tmpId = p?.tmpId || uuid();
    }
}


export { PaqEntry, getPriorityScore };