//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useLayoutEffect, useCallback, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Chip, ListSubheader } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from '@mui/material/styles';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import SearchIcon from '@mui/icons-material/Search';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useImmerReducer } from 'use-immer';
import debounce from "lodash.debounce";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../../context/UserProvider";
import { usePaths } from "../../../context/PathsProvider";
import { useHttp } from "../../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { GradeSmiley } from "../GradeSmiley";
import { CircularProgress } from "../CircularProgress";
import { ImperativeSign } from "../ImperativeSign";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerURL } from "../../../config";
import { Referential } from "../../../models/Referential";
import { CriterionResponse } from "../../../models/CriterionResponse";
import { highlightText } from "../../../lib/lib";
import { getColor, colors } from "../../../theme/colors";
import Approved from "../../../img/Approved";



const EvalCockpit = () => {

    const { httpRequest } = useHttp();
    const { isLoggedIn, user, selectedEstablishment } = useUser();
    const { paths } = usePaths();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [criterionResponses, setCriterionResponses] = useState(undefined);
    const [searchFilter, setSearchFilter] = useState("");
    const { customer_hid } = useParams();
    const theme = useTheme();
    // const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referential_ImID = params.get('referential_ImID');
    const scrollToId = useRef(params.get('scrollToId'));

    //page colors
    const pageColors = useRef({
        filterColor: getColor(colors.forest.main),
        chapterBackgroundColor: getColor(colors.forest.tint2, 0.2),
        chapterColor: getColor(colors.forest.main),
        chapterHoverColor: getColor(colors.forest.tint2, 0.4),
        chapterProgressColor: getColor(colors.greenLemon.main),
        chapterProgressBackgroundColor: getColor(colors.greenLemon.main, 0.35),
        objectiveBackgroundColor: getColor(colors.greenLemon.main, 0.1),
        objectiveHoverColor: getColor(colors.greenLemon.main, 0.2),
        objectiveColor: getColor(colors.greenLemon.main),
        objectiveProgressColor: getColor(colors.azure.main),
        objectiveProgressBackgroundColor: getColor(colors.azure.main, 0.35),
        criterionBackgroundColor: getColor(colors.greenLemon.main, 0.05),
        criterionHoverColor: getColor(colors.azure.main, 0.2),
        criterionColor: getColor(colors.azure.main),
        criterionProgressColor: getColor(colors.azure.main),
        criterionProgressBackgroundColor: getColor(colors.azure.main, 0.35),
    });
    //shorter for use in the page
    const pc = { ...pageColors.current };

    const reducer = (draft, action) => {
        switch (action?.type) {
            case "init":
                return action.init();
            case "update":
                action.update(draft);
                draft.is_modified = draft.isModified();
                draft.contain_empty_parents = draft.containEmptyParents();
                break;
            case "update_and_filter":
                action.update(draft);
                draft.is_modified = draft.isModified();
                draft.contain_empty_parents = draft.containEmptyParents();
            case "filter":
                draft.filter(searchFilter);
                break;
            case "expand_all":
                draft.expand();
                break;
            case "expand_item":
                action.getItem(draft).expand();
                break;
            case "show_archived":
                draft.showArchivedElements();
                break;
        }
    }

    //selected referential for display and interactions
    const [referential, dispatchReferential] = useImmerReducer(reducer, new Referential());

    const fetchEval = useCallback(async () => {
        setIsLoading(true);
        let response = await httpRequest({
            url: `${getServerURL()}/customer/${customer_hid}/${selectedEstablishment._id}/aev/ref/${referential_ImID}/cockpit`,
            method: 'get',
            showLoading: true,
            withCredentials: true
        });
        if (response.status === 200) {
            let ref = new Referential(response?.data?.referential);
            // ref.filter(searchFilter, activityFilter);
            dispatchReferential({
                type: "init",
                init: () => ref
            });
            let critResponses = {};
            Object.keys(response?.data?.criterionResponses).map(key => {
                critResponses[key] = new CriterionResponse(response.data.criterionResponses[key]);
            })
            setCriterionResponses(critResponses);
        }
        setIsLoading(false);
    }, [customer_hid, selectedEstablishment, referential_ImID])

    useEffect(() => {
        if (!isLoggedIn || !user) return;
        fetchEval();
    }, [isLoggedIn, user, selectedEstablishment, customer_hid, referential_ImID])

    useLayoutEffect(() => {
        if (!scrollToId?.current || !criterionResponses || !referential) return;
        const element = document.getElementById(scrollToId?.current);
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
        scrollToId.current = null;
    }, [criterionResponses, referential])

    return (
        <Box sx={{ width: '100%' }}>
            <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", width: "100%", gap: "20px", padding: "8px" }}>
                <Box sx={{ width: "100px" }} />
                <TextField
                    id="search"
                    type="search"
                    label="Filtrer"
                    variant="standard"
                    onChange={debounce((event) => {
                        setSearchFilter(event.target.value);
                        dispatchReferential({
                            type: "filter"
                        })
                    }, [500])}
                    sx={{
                        width: "400px",
                        display: "flex",
                        flexShrink: 1,
                        color: pc.filterColor,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon sx={{ color: pc.filterColor }} />
                            </InputAdornment>
                        )
                    }}
                />
                <Box sx={{ display: "flex", justifyContent: "end", alignItems: 'center', width: "100px", marginRight: "25px" }}>
                    {/* Just comment it out for now, to let the logic in place for later maybe */}
                    {/* <IconButton
                        disabled={referential?.chapters?.length === 0}
                        onClick={() => dispatchReferential({ type: "show_archived" })}
                        sx={{ backgroundColor: pc.chapterBackgroundColor }}
                    >
                        {referential?.showArchived ? <ArchiveIcon /> : <UnarchiveIcon />}
                    </IconButton> */}
                    <IconButton
                        disabled={referential?.chapters?.length === 0}
                        onClick={() => dispatchReferential({ type: "expand_all" })}
                        sx={{ backgroundColor: pc.chapterBackgroundColor }}
                    >
                        {referential?.expandAll ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
                    </IconButton>
                </Box>
            </Toolbar>
            <Box sx={{ overflowY: "auto", height: `calc(100vh - 128px)` }} >
                {!isLoading &&
                    <>
                        {referential?.chapters?.length === 0 ?
                            <Box sx={{ position: "fixed", top: "50vh", left: "calc(50vw - 200px)" }}>
                                <div style={{ width: "400px", textAlign: "center", fontWeight: "bold" }}>Aucun référentiel trouvé</div>
                            </Box>
                            :
                            <>
                                <List
                                    sx={{ width: '100%', padding: "16px", bgcolor: 'background.paper' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    subheader={
                                        <ListItemText />
                                    }
                                >
                                    {referential?.chapters?.map((chapter, chapterIndex) => {
                                        //*******************************************************************chapitres**********************************************************************
                                        if (!chapter.hidden && (referential?.showArchived || (!referential?.showArchived && !chapter.is_archived))) return (
                                            <div key={chapter.name + chapterIndex}>
                                                <ListSubheader
                                                    sx={{ p: 0, top: "-1px", zIndex: 2 }}
                                                // ref={el => chapterRefs.current[chapterIndex] = el}
                                                >
                                                    <ListItemButton
                                                        sx={{
                                                            backgroundColor: pc.chapterBackgroundColor,
                                                            borderRadius: "30px",
                                                            ":hover": {
                                                                backgroundColor: pc.chapterHoverColor
                                                            },
                                                            marginBottom: "12px",
                                                            boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.4)"
                                                        }}
                                                        onClick={() => {
                                                            dispatchReferential({
                                                                type: "expand_item",
                                                                getItem: draft => draft.chapters[chapterIndex]
                                                            });
                                                        }}>
                                                        <Chip
                                                            label={
                                                                <span>{highlightText(`${Number(chapterIndex) + 1}`, searchFilter)}</span>
                                                            }
                                                            sx={{
                                                                backgroundColor: pc.chapterColor,
                                                                color: "white",
                                                                marginRight: "16px",
                                                                paddingLeft: { xs: "none", md: "12px" },
                                                                fontSize: "16px",
                                                                '::before': {
                                                                    content: '"Chapitre "',
                                                                    display: { xs: 'none', md: 'inline' }, // Show "Chapitre" only on small screens
                                                                },
                                                            }}
                                                        />
                                                        <ListItemText primaryTypographyProps={{ fontSize: '18px' }} >
                                                            <span style={{ fontWeight: "bold", color: pc.chapterColor }}> {highlightText(`${chapter.name}`, searchFilter)}</span>
                                                        </ListItemText>
                                                        <Box sx={{
                                                            display: "flex",
                                                            gap: "12px",
                                                            [theme.breakpoints.down('md')]: {
                                                                maxWidth: "40px",
                                                                flexWrap: "wrap-reverse",
                                                            },
                                                        }}>
                                                            <Box sx={{ width: "40px", height: "40px", display: { xs: chapter?.isApproved ? "block" : "none", md: "block" } }}>
                                                                {chapter?.isApproved &&
                                                                    <Approved />
                                                                }
                                                            </Box>
                                                            <Box sx={{ width: "40px", height: "40px", display: { xs: chapter?.grade ? "block" : "none", md: "block" } }}>
                                                                {chapter?.grade &&
                                                                    <Tooltip title="Note moyenne sur 4" arrow>
                                                                        <Box>
                                                                            <GradeSmiley
                                                                                grade={chapter?.grade}
                                                                            />
                                                                        </Box>
                                                                    </Tooltip>
                                                                }
                                                            </Box>
                                                            <Tooltip title="Taux de remplissage" arrow>
                                                                <Box style={{ width: "40px", heigh: "40px" }}>
                                                                    <CircularProgress
                                                                        value={Number(chapter.progress) * 100}
                                                                        progressColor={pc.chapterProgressColor}
                                                                        backgroundColor={pc.chapterProgressBackgroundColor}
                                                                    />
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>
                                                        <IconButton>
                                                            {chapter.isOpen ? <ExpandLess style={{ color: pc.chapterColor }} /> : <ExpandMore style={{ color: pc.chapterColor }} />}
                                                        </IconButton>
                                                    </ListItemButton>
                                                </ListSubheader>
                                                <Collapse in={chapter.isOpen} timeout="auto" unmountOnExit>
                                                    {/* **********************************************************objectives****************************************************************** */}
                                                    <List component="div" disablePadding>
                                                        {chapter?.objectives?.map((objective, objectiveIndex) => {
                                                            if (!objective.hidden && (referential?.showArchived || (!referential?.showArchived && !objective.is_archived))) return (
                                                                <div key={objective.name + objectiveIndex}>
                                                                    <div style={{ left: 24 }}></div>
                                                                    <ListItemButton
                                                                        sx={{
                                                                            ml: { xs: 2, md: 4 },
                                                                            backgroundColor: pc.objectiveBackgroundColor,
                                                                            borderRadius: "30px",
                                                                            ":hover": {
                                                                                backgroundColor: pc.objectiveHoverColor
                                                                            },
                                                                            marginBottom: "12px",
                                                                            boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.20)"
                                                                        }}
                                                                        id={objective.immutable_id}
                                                                        onClick={() => {
                                                                            dispatchReferential({
                                                                                type: "expand_item",
                                                                                getItem: draft => draft.chapters[chapterIndex].objectives[objectiveIndex]
                                                                            });
                                                                        }}
                                                                    >
                                                                        <Chip
                                                                            label={
                                                                                <span>{highlightText(`${Number(chapterIndex) + 1}.${Number(objectiveIndex) + 1}`, searchFilter)}</span>
                                                                            }
                                                                            sx={{
                                                                                backgroundColor: pc.objectiveBackgroundColor,
                                                                                color: pc.objectiveColor,
                                                                                border: `1px solid ${pc.objectiveColor}`,
                                                                                marginRight: "16px",
                                                                                paddingLeft: { xs: "none", md: "12px" },
                                                                                fontSize: "16px",
                                                                                '::before': {
                                                                                    content: '"Objectif "',
                                                                                    display: { xs: 'none', md: 'inline' }, // Show "Chapitre" only on small screens
                                                                                },
                                                                            }}
                                                                        />
                                                                        <ListItemText primaryTypographyProps={{ fontSize: "16px" }} >
                                                                            <span>{highlightText(`${objective.name}`, searchFilter)}</span>
                                                                        </ListItemText>
                                                                        <Box sx={{
                                                                            display: "flex",
                                                                            gap: "12px",
                                                                            [theme.breakpoints.down('md')]: {
                                                                                maxWidth: "40px",
                                                                                flexWrap: "wrap-reverse",
                                                                            },
                                                                        }}>
                                                                            <Box sx={{ width: "40px", height: "40px", display: { xs: objective?.isApproved ? "block" : "none", md: "block" } }}>
                                                                                {objective?.isApproved &&
                                                                                    <Approved />
                                                                                }
                                                                            </Box>
                                                                            <Box sx={{ width: "40px", height: "40px", display: { xs: objective?.grade ? "block" : "none", md: "block" } }}>
                                                                                {objective?.grade &&
                                                                                    <Tooltip title="Note moyenne sur 4" arrow>
                                                                                        <Box>
                                                                                            <GradeSmiley grade={objective?.grade} />
                                                                                        </Box>
                                                                                    </Tooltip>
                                                                                }
                                                                            </Box>
                                                                            <Tooltip title="Taux de remplissage" arrow>
                                                                                <Box style={{ width: "40px", heigh: "40px" }}>
                                                                                    <CircularProgress
                                                                                        value={Number(objective.progress) * 100}
                                                                                        progressColor={pc.objectiveProgressColor}
                                                                                        backgroundColor={pc.objectiveProgressBackgroundColor}
                                                                                    />
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </Box>
                                                                        <IconButton>
                                                                            {objective.isOpen ? <ExpandLess style={{ color: pc.objectiveColor }} /> : <ExpandMore style={{ color: pc.objectiveColor }} />}
                                                                        </IconButton>
                                                                    </ListItemButton>
                                                                    <Collapse in={objective.isOpen} timeout="auto" unmountOnExit>
                                                                        {/* ******************************************criteria***************************************************************** */}
                                                                        <List component="div" disablePadding>
                                                                            {objective?.criteria?.map((criterion, criterionIndex) => {
                                                                                const isDisabled = criterion?.isEmptyParent;
                                                                                if (!criterion.hidden && (referential?.showArchived || (!referential?.showArchived && !criterion.is_archived))) return (
                                                                                    <div key={criterion.name + criterionIndex}>
                                                                                        <div style={{ left: 64 }}></div>
                                                                                        <a
                                                                                            id={criterion.immutable_id}
                                                                                            onClick={event => {
                                                                                                if (!isDisabled) {
                                                                                                    if (!event.ctrlKey) {
                                                                                                        event.preventDefault();
                                                                                                        // Create a new URLSearchParams object
                                                                                                        const searchParams = new URLSearchParams(window.location.search);
                                                                                                        // Add criterion_ImID as scrollTo param
                                                                                                        searchParams.set("scrollToId", objective.immutable_id);
                                                                                                        window.history.replaceState(null, "", `${window.location.pathname}?${searchParams.toString()}`);
                                                                                                        navigate(paths.evalCriterion.app({
                                                                                                            customer_hid: customer_hid,
                                                                                                            establishment_id: selectedEstablishment._id,
                                                                                                            referential_ImID: referential_ImID,
                                                                                                            criterion_ImID: criterion.immutable_id
                                                                                                        }));
                                                                                                    } else if (event.ctrlKey) {
                                                                                                        event.preventDefault();
                                                                                                        window.open(paths.evalCriterion.app({
                                                                                                            customer_hid: customer_hid,
                                                                                                            establishment_id: selectedEstablishment._id,
                                                                                                            referential_ImID: referential_ImID,
                                                                                                            criterion_ImID: criterion.immutable_id
                                                                                                        }), "_blank");
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                color: pc.criterionColor,
                                                                                                textDecoration: "none",
                                                                                                cursor: isDisabled ? "default" : "default",
                                                                                            }}
                                                                                        >
                                                                                            <ListItemButton
                                                                                                sx={{
                                                                                                    ml: { xs: 4, md: 8 },
                                                                                                    pr: "56px",
                                                                                                    ":hover": {
                                                                                                        backgroundColor: pc.criterionHoverColor,
                                                                                                    },
                                                                                                    marginBottom: "12px",
                                                                                                    borderRadius: "30px",
                                                                                                    backgroundColor: pc.criterionBackgroundColor,
                                                                                                    boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.05)"
                                                                                                }}
                                                                                                disabled={isDisabled}
                                                                                            >
                                                                                                <Chip
                                                                                                    label={
                                                                                                        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                                                                            <div>{highlightText(`${Number(chapterIndex) + 1}.${Number(objectiveIndex) + 1}.${Number(criterionIndex) + 1}`, searchFilter)}</div>
                                                                                                            {criterion.important &&
                                                                                                                <Tooltip title="Critère impératif" arrow>
                                                                                                                    <Box sx={{ width: "25px", height: "25px" }}>
                                                                                                                        <ImperativeSign />
                                                                                                                    </Box>
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                        </Box>

                                                                                                    }
                                                                                                    sx={{
                                                                                                        backgroundColor: pc.criterionBackgroundColor,
                                                                                                        color: pc.criterionColor,
                                                                                                        border: `1px solid ${pc.criterionColor}`,
                                                                                                        marginRight: "16px",
                                                                                                        fontSize: "16px",
                                                                                                        paddingLeft: { xs: "none", md: "12px" },
                                                                                                        '::before': {
                                                                                                            content: '"Critère "',
                                                                                                            display: { xs: 'none', md: 'inline' },
                                                                                                        },
                                                                                                    }}
                                                                                                />
                                                                                                {/* <Chip
                                                                                                    label={
                                                                                                        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                                                                            <div>{highlightText(`${isSmallScreen ? "" : "Critère "}${Number(chapterIndex) + 1}.${Number(objectiveIndex) + 1}.${Number(criterionIndex) + 1}`, searchFilter)}</div>
                                                                                                            {criterion.important &&
                                                                                                                <Tooltip title="Critère impératif" arrow>
                                                                                                                    <Box sx={{ width: "25px", height: "25px" }}>
                                                                                                                        <ImperativeSign />
                                                                                                                    </Box>
                                                                                                                </Tooltip>
                                                                                                            }
                                                                                                        </Box>
                                                                                                    }
                                                                                                    sx={{
                                                                                                        backgroundColor: pc.criterionBackgroundColor,
                                                                                                        color: pc.criterionColor,
                                                                                                        border: `1px solid ${pc.criterionColor}`,
                                                                                                        marginRight: "16px",
                                                                                                        fontSize: "16px"
                                                                                                    }}
                                                                                                /> */}
                                                                                                <ListItemText primaryTypographyProps={{ fontSize: '1rem' }} sx={{ ":hover": { textDecoration: "underline" } }} >
                                                                                                    <span>{highlightText(`${criterion.name}`, searchFilter)}</span>
                                                                                                </ListItemText>
                                                                                                <Box sx={{
                                                                                                    display: "flex",
                                                                                                    alignItems: "center",
                                                                                                    gap: "12px",
                                                                                                    [theme.breakpoints.down('md')]: {
                                                                                                        maxWidth: "40px",
                                                                                                        flexWrap: "wrap-reverse",
                                                                                                    },
                                                                                                }}>
                                                                                                    <Box sx={{ width: "40px", height: "40px", display: { xs: criterion?.isApproved ? "block" : "none", md: "block" } }}>
                                                                                                        {criterion?.isApproved &&
                                                                                                            <Approved />
                                                                                                        }
                                                                                                    </Box>
                                                                                                    <Box sx={{ width: "40px", height: "40px", display: { xs: criterion?.grade ? "block" : "none", md: "block" } }}>
                                                                                                        {criterion?.grade &&
                                                                                                            <Tooltip title="Note moyenne sur 4" arrow>
                                                                                                                <Box>
                                                                                                                    <GradeSmiley grade={criterion.grade} />
                                                                                                                </Box>
                                                                                                            </Tooltip>
                                                                                                        }
                                                                                                    </Box>
                                                                                                    <Tooltip title="Taux de remplissage" arrow>
                                                                                                        <Box style={{ width: "40px", heigh: "40px" }}>
                                                                                                            <CircularProgress
                                                                                                                value={Number(criterion.progress) * 100}
                                                                                                                progressColor={pc.criterionProgressColor}
                                                                                                                backgroundColor={pc.criterionProgressBackgroundColor}
                                                                                                            />
                                                                                                        </Box>
                                                                                                    </Tooltip>
                                                                                                </Box>
                                                                                                {/* <IconButton
                                                                                                onClick={() => {
                                                                                                    navigate(paths.evalCriterion.app({
                                                                                                        customer_hid: customer_hid,
                                                                                                        establishment_id: selectedEstablishment._id,
                                                                                                        referential_ImID: referential_ImID,
                                                                                                        criterion_ImID: criterion.immutable_id
                                                                                                    }))
                                                                                                }}>
                                                                                                <EditIcon />
                                                                                            </IconButton> */}
                                                                                            </ListItemButton>
                                                                                        </a>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </List>
                                                                    </Collapse>
                                                                </div>
                                                            );
                                                        })}

                                                    </List>
                                                </Collapse>
                                            </div>
                                        );
                                    })}
                                </List>
                            </>
                        }
                    </>
                }

            </Box>
        </Box >
    );
};

export default EvalCockpit;
